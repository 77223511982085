<template>
  <v-form
    v-model="valid"
    :disabled="
      $store.state.form.cell.length == 0 && !this.$store.state.form.newUser
    "
    ref="questionare_form"
  >
    <v-sheet color="#565c72" rounded>
      <h1 class="section-title text-capitalize">{{ $t("menu4") }}</h1>
    </v-sheet>
    <v-row>
      <v-col cols="12" lg="10">
        <v-sheet color="#eaeaea" class="fieldgroup">
          <p>{{ $t("foodTxt") }}</p>
          <p>
            {{ $t("foodWarn") }}
          </p>
        </v-sheet>
        <div>
          <!-- <v-text-field class="error-questionare" :rules="[userSelectedFoods > 0 || $t('error9')]"></v-text-field> -->

          <v-sheet
            color="#eaeaea"
            class="fieldgroup"
            v-for="(categoryItem, index1) in questionnaireOptions"
            :key="index1"
            v-bind:value="categoryItem.foodCategoryName"
          >
            <v-sheet color="#11d3b3" class="px-3 mb-4">
              <h2>
                {{ $t("foodCategories." + categoryItem.foodCategoryName) }}
              </h2>
              <v-row>
                <v-checkbox
                  class="px-2"
                  :label="$t('all')"
                  color="white"
                  v-model="all[index1]"
                  @change="selectAll(index1)"
                ></v-checkbox>
                <v-checkbox
                  :label="$t('none')"
                  color="white"
                  v-model="none[index1]"
                  @change="clearAll(index1)"
                ></v-checkbox>
              </v-row>
            </v-sheet>
            <v-btn-toggle
              v-model="foodSelections[index1]"
              multiple
              @change="select(index1)"
            >
              <v-btn
                v-for="(foodItem, index2) in categoryItem.foodOptions"
                :key="index2"
                :disabled="disabled"
                v-bind:value="foodItem.id"
              >
                {{ foodItem.name }}
              </v-btn>
            </v-btn-toggle>
          </v-sheet>

          <v-sheet color="#eaeaea" class="fieldgroup">
            <v-sheet color="#11d3b3" class="px-3 mb-4">
              <h2>
                {{ $t("nonClinicalConditionsLabel") }}
              </h2>
            </v-sheet>
            <v-btn-toggle v-model="nonClinicalSelections" multiple>
              <v-btn
                v-for="(condition, index2) in nonClinicalOptions"
                :key="index2"
                :disabled="disabled"
                v-bind:value="condition.id"
              >
                {{ condition.name }}
              </v-btn>
            </v-btn-toggle>
          </v-sheet>
        </div>
      </v-col>
    </v-row>
    <div class="d-flex flex-column flex-grow-1">
      <v-btn
        class="ma-4 align-self-end nextBtn"
        plain
        :right="true"
        @click="validate"
      >
        {{ $t("next") }}
        <v-icon right class="nextIcon"> mdi-chevron-right</v-icon>
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  data: () => ({
    valid: false,
    all: [],
    none: []
  }),
  watch: {
    lang() {
      this.resetQuestionnaireOptions();
      this.getQuestionnaire(this.lang);
      this.resetNonClinicalOptions();
      this.getNonClinicalConditions(this.lang);
    },
    disabled() {
      if (
        !this.disabled &&
        ((this.selectedFoodsCon.length > 0 &&
          this.foodSelections.length == 0 &&
          this.questionnaireOptions.length > 0) ||
          (this.selectedNonClinical.length == 0 && this.nonClinicalOptions > 0))
      ) {
        this.loadSelected();
      }
    },
    questionnaireOptionsLen() {
      if (this.questionnaireOptions.length > 0) {
        if (
          !this.disabled &&
          ((this.selectedFoodsCon.length > 0 &&
            this.foodSelections.length == 0) ||
            (this.nonClinicalSelections.length == 0 &&
              this.selectedNonClinical.length == 0))
        ) {
          this.loadSelected();
        }
      }
    }
  },
  created() {
    this.$store.commit("setStep", { step: 4 });
    this.getQuestionnaire(this.lang);
    this.getNonClinicalConditions(this.lang);
  },
  methods: {
    ...mapActions(["getQuestionnaire", "getNonClinicalConditions"]),
    ...mapMutations([
      "resetQuestionnaireOptions",
      "resetNonClinicalOptions",
      "foodQuestionnaireConversion"
    ]),

    validate() {
      if (this.$refs.questionare_form.validate()) {
        let tmp = [];
        for (var x of this.foodSelections) {
          if (x) {
            tmp = x.concat(tmp);
          }
        }
        this.$store.commit("setSelectedFoodsCon", tmp);
        this.$store.commit("setStep", { step: 5 });
        this.$store.commit("setStep4Valid", { step4Valid: true });
        this.$router.push("/step5");
      } else {
        const that = this;
        setTimeout(function() {
          that.$el.querySelector(".error--text").scrollIntoView({
            behavior: "smooth"
          });
        }, 1000);

        return null;
      }
    },

    selectAll(index) {
      if (this.none[index]) {
        this.none[index] = false;
      }
      let tmp = [];
      if (this.all[index]) {
        for (var x of this.questionnaireOptions[index].foodOptions) {
          tmp.push(x.id);
        }
        this.foodSelections[index] = tmp;
      } else {
        this.foodSelections[index] = [];
      }
    },
    clearAll(index) {
      if (this.all[index]) {
        this.all[index] = false;
      }
      if (this.none[index]) this.foodSelections[index] = [];
    },
    select(index) {
      if (
        this.foodSelections[index].length !=
        this.questionnaireOptions[index].foodOptions.length
      ) {
        this.all[index] = false;
      }
      if (this.foodSelections[index].length > 0) {
        this.none[index] = false;
      }
    },
    loadSelected() {
      let tmp = [];
      for (var i = 0; i < this.questionnaireOptions.length; i++) {
        for (var j of this.selectedFoodsCon) {
          for (var k of this.questionnaireOptions[i].foodOptions) {
            if (k.id == j) {
              tmp.push(k.id);
            }
          }
        }
        this.foodSelections[i] = tmp;
        tmp = [];
      }

      let tmp2 = [];

      for (var i of this.selectedNonClinical) {
        for (var j of this.nonClinicalOptions) {
          if (j.id == i) {
            tmp2.push(i);
            break;
          }
        }
      }
      this.nonClinicalSelections = tmp2;
    }
  },
  computed: {
    ...mapState({
      selectedFoodsCon: state => state.form.selectedFoodsCon,
      selectedNonClinical: state => state.form.selectedNonClinical
    }),

    userSelectedFoods: {
      get() {
        if (this.selectedFoodsCon && this.selectedFoodsCon.length > 0) {
          return this.selectedFoodsCon.length;
        } else {
          let tmp = [];
          for (var x of this.foodSelections) {
            if (x) {
              tmp = x.concat(tmp);
            }
          }

          return tmp.length;
        }
      }
    },

    disabled: {
      get() {
        return (
          this.$store.state.form.cell.length == 0 &&
          !this.$store.state.form.newUser
        );
      }
    },
    questionnaireOptions: {
      get() {
        return this.$store.state.form.questionnaireOptions;
      }
    },
    nonClinicalOptions: {
      get() {
        return this.$store.state.form.nonClinicalOptions;
      }
    },
    questionnaireOptionsLen: {
      get() {
        return this.$store.state.form.questionnaireOptions.length;
      }
    },
    nonClinicalOptionsLen: {
      get() {
        return this.$store.state.form.nonClinicalOptions.length;
      }
    },
    foodSelections: {
      get() {
        return this.$store.state.form.selectedFoods;
      },
      set(value) {
        this.$store.commit("setSelectedFoods", { selectedFoods: value });
      }
    },
    selectedNonClinical: {
      get() {
        return this.$store.state.form.selectedNonClinical;
      },
      set(value) {
        this.$store.commit("setSelectedNonClinical", {
          selectedNonClinical: value
        });
      }
    },
    nonClinicalSelections: {
      get() {
        return this.$store.state.form.nonClinicalSelections;
      },
      set(value) {
        this.$store.commit("setNonClinicalSelections", {
          nonClinicalSelections: value
        });
      }
    },
    lang: {
      get() {
        return this.$store.state.form.lang;
      }
    }
  },
  destroyed() {
    this.resetQuestionnaireOptions();
    this.resetNonClinicalOptions();
  }
};
</script>
<style lang="scss" scoped>
.section-title {
  color: white;
  padding-left: 50px;
  margin: 10px 0;
}
.fieldgroup {
  padding: 20px;
  margin-bottom: 10px;
}
.fieldgroup .v-btn-toggle {
  background: none !important;
  flex-wrap: wrap;
}
.fieldgroup .v-btn--contained {
  border-radius: 5px !important;
  height: 35px !important;
  margin: 5px;
  font-size: 1.2em;
}

.fieldgroup .v-btn--contained.v-item--active {
  background-color: rgb(86, 92, 114) !important;
}
.fieldgroup .theme--dark .v-btn.v-btn {
  background: transparent;
}

.fieldgroup .theme--dark.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
  border: 2px solid rgb(86, 92, 114) !important;
}

.v-btn {
  text-transform: capitalize;
}
</style>
