<template>
  <div id="app">
    <v-app id="vapp">
      <Navigation class="sidebar" v-if="$route.name != 'finish' && $route.name != 'login'" />
      <v-main class="main" :class="{ 'grey-background': $route.name != 'finish' }">
        <v-container fluid :class="{ 'pa-0': $route.name == 'finish' }">
          <router-view></router-view>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import Navigation from "@/components/Navigation";

export default {
  name: "App",

  components: {
    Navigation
  },

  data() {
    return {};
  }
};
</script>

<style lang="scss">
@font-face {
  font-family: "BebasNeueBoldB";
  src: local("BebasNeueBoldB"),
    url(/fonts/BebasNeueBoldB/BebasNeueBoldB.ttf) format("truetype");
}

@font-face {
  font-family: "ArtegraSansExtended-Regular";
  src: local("ArtegraSansExtended-Regular"),
    url(/fonts/ArtegraSansExtended-Regular.woff) format("truetype");
  // font-weight: 700;
}

#vapp {
  background: none;
  font-family: ArtegraSansExtended-Regular, sans-serif;
}

.grey-background {
  background: #eaeaea;
}

.v-btn__content {
  color: #000000 !important;
}


.error-questionare .v-text-field__slot {
    display: none !important;
}
.error-questionare .v-text-field__details .v-messages__message {
    padding: 20px 0 !important;
    font-size: 15px !important;
    text-align: center !important;
    line-height: inherit !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0px 1000px #370b64 inset;
  -webkit-box-shadow: 0 0 0px 1000px #370b64 inset !important;
  -webkit-text-stroke-color: #370b64 !important;
  -webkit-text-fill-color: #fff !important;
}

::-webkit-scrollbar {
  width: 0px;
}

:root {
  scrollbar-color: transparent transparent !important;
  scrollbar-width: none !important;
}

.nextBtn {
  font-size: 30px !important;
  color: #fff;
  letter-spacing: 0.02em;
}

.nextIcon {
  font-size: 40px !important;
  background: -moz-linear-gradient(top, #12d0b2 0%, #640094 100%);
  background: -webkit-linear-gradient(top, #12d0b2 0%, #640094 100%);
  background: linear-gradient(to bottom, #12d0b2 0%, #640094 100%);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-wrapper {
  position: relative;
  min-height: 100vh;
  overflow: hidden !important;
}

.theme--dark.v-text-field > .v-input__control > .v-input__slot:before {
    border-color: #000000 !important;
}

.theme--dark.v-text-field.v-input--is-disabled .v-input__slot::before {
  border-image: repeating-linear-gradient(to right, #000000 0px, #000000 2px, transparent 2px, transparent 4px) 1 repeat !important;
}

#app .v-input--selection-controls__input .v-icon,
#app .theme--dark.v-input--selection-controls.v-input--is-disabled:not(.v-input--indeterminate) .v-icon {
    color: #000000 !important;
}

.v-autocomplete.v-select--is-menu-active .v-input__icon--append .v-icon {
  transform: none;
}

.theme--dark.v-select .v-select__selection--comma {
  color: #000000;
}

.theme--dark.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset {
  color:#000000;
}

.theme--dark.v-icon {
  color: #000000 !important;
}

.main .fieldgroup,
.main .theme--dark.v-input input,
.main .theme--dark.v-input textarea {
  color: #565c72 !important;
}


.v-application .error--text {
  color: rgb(255, 0, 0) !important;
  caret-color: rgb(255, 0, 0) !important;
}

.v-application .primary--text {
  color: rgb(255, 0, 0) !important;
  caret-color: rgb(255, 0, 0) !important;
}

.theme--dark.v-label {
  color: #565c72 !important;
}

.login-form {
  width: 360px;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }

  .v-input__slot {
    background-color: transparent !important;
  }
}

.login-div {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 10vh;
  overflow-y: scroll !important;
  overflow-x: auto !important;
}

.sidebar {
  overflow: hidden !important;
  background-color: #565c72 !important;
}

.container {
  padding: 12px 0 !important;
}

.v-application .rounded {
  border-radius: 0 0 4px !important;
}

#menuList {
  background-color: transparent !important;
  border-color: #1e1e1e;
  text-transform: capitalize;
  overflow-y: scroll !important;
}
</style>
