<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      min-width="250px"
      app
      disable-resize-watcher
      :permanent="$vuetify.breakpoint.mdAndUp"
      :absolute="$vuetify.breakpoint.smAndDown"
      :temporary="$vuetify.breakpoint.smAndDown"
      class="sidebar"
    >
      <v-list id="menuList">
        <img
          src="@/assets/img/myflex.png"
          alt="logo"
          width="100%"
          height="100%"
        />
        <v-autocomplete
          v-model="model"
          outlined
          dense
          app
          :items="users"
          :loading="isLoading"
          hide-no-data
          item-text="Result"
          item-value="name"
          class="flex-grow-0 pa-4 search-input"
          append-icon="mdi-magnify"
          :search-input.sync="search"
          return-object
        >
        </v-autocomplete>
        <v-list-item @click="clearForm" id="newUsr" class="pb-2">
          <v-icon class="mx-2"> mdi-account-plus </v-icon>
          {{ $t("newUser") }}</v-list-item
        >
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          link
          @click="setCurrentStep(index)"
        >
          <v-list-item-content>
            <v-list-item-title
               
              :class="{ selectedItem: currentStep == index + 1 }"
              >{{ item }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <div
          class="d-flex justify-center pt-6 mt-6"
          style="position: fixed; bottom: 100px; margin-left: 70px;"
        >
          <v-btn color="#eaeaea" @click="logout">{{
            $t("logout")
          }}</v-btn>
        </div>
        <div class="lang-row  d-flex justify-center mb-6">
          <v-btn
            color="white"
            fab
            dark
            class="mx-4"
            @click="
              $i18n.locale = 'el';
              setLang({ lang: 'el' });
            "
          >
            <img src="@/assets/img/el.png" alt="el" width="56" height="56" />
          </v-btn>
          <v-btn
            color="white"
            fab
            dark
            @click="
              $i18n.locale = 'en';
              setLang({ lang: 'en' });
            "
          >
            <img src="@/assets/img/en.png" alt="en" width="56" height="56" />
          </v-btn>
          <v-btn
            color="white"
            fab
            dark
            class="mx-4"
            @click="
              $i18n.locale = 'de';
              setLang({ lang: 'de' });
            "
          >
            <img src="@/assets/img/de.png" alt="de" width="82" height="60" />
          </v-btn>
        </div>
      </v-list>
    </v-navigation-drawer>
    <v-fab-transition>
      <v-btn
        @click="drawer = !drawer"
        plain
        fab
        x-large
        class="expandBtn"
        v-if="drawer == false"
        elevation="2"
        absolute
        center
        right
        color="rgb(92,5,118)"
        ><v-icon>mdi-chevron-right</v-icon></v-btn
      >
    </v-fab-transition>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      drawer: true,
      search: null,
      model: null,
      isLoading: false
    };
  },
  watch: {
    search() {
      if (this.search == null || this.search == "") {
        this.resetSearchResults();
      }
      // if (this.users != null && this.users.length > 0) return;

      if (this.isLoading) return;

      this.isLoading = true;

      if (this.search != null && this.search.length >= 6) {
        this.phoneSearch(this.search);
      }
      this.isLoading = false;
    },
    model() {
      this.search = "";
      if (this.model) {
        this.newUser = false;
        if (this.model.length < 10) {
          this.setUserId({ userId: this.model.id });
          this.setCell({ cell: this.model.cell });
          this.setFirstName({ firstName: this.model.name.split(" ")[0] });
          this.setLastName({ lastName: this.model.name.split(" ")[1] });
        } else {
          this.editUser = true;
          this.setUserId({ userId: this.model.id });
          this.setCell({ cell: this.model.cell });
          this.setFirstName({ firstName: this.model.name.split(" ")[0] });
          this.setLastName({ lastName: this.model.name.split(" ")[1] });
          this.setBirthday({ birthday: this.model.dob || null });
          this.setEmail({ email: this.model.email || null });
          this.setGender({ gender: this.model.gender || null });
          this.setAddress({ address: this.model.address || null });
          this.setZipCode({ zip_code: this.model.zip_code || null });
          this.setIsSmoker({ isSmoker: this.model.is_smoking || null });
          this.setHasPathologies({
            hasPathologies: this.model.has_pathology || null
          });
          this.setSelectedMedicalConditions({
            selectedMedicalConditions: this.model.medical_conditions || []
          });
          this.setSelectedNonClinical({
            selectedNonClinical: this.model.non_clinical_conditions || []
          });
          this.setKneeProblem({ kneeProblem: this.model.has_knees || null });
          this.setShoulderProblem({
            shoulderProblem: this.model.has_shoulders || null
          });
          this.setBackProblem({ backProblem: this.model.has_backpain || null });
          this.setSkeletalProblem({
            skeletalProblem: this.model.has_skeletic || null
          });
          this.setHealthInfo({ healthInfo: this.model.health_comment || null });
          this.setWantsPersonalTrainer({
            wantsPersonalTrainer: this.model.gym_personal || null
          });
          this.setDoesWorkout({ doesWorkout: this.model.gym || null });
          this.setYearsOfWorkout({
            yearsOfWorkout: this.model.gym_years || null
          });
          this.setExerciseType({ exerciseType: this.model.gym_type || null });
          this.setExerciseDays({
            exerciseDays: this.model.gym_available || null
          });
          this.setSelectedFoodsCon(this.model.foods || []);
          this.phoneCheck({ cell: this.model.cell, country_code: "" });
        }
      }
    },
    newUser() {
      if (this.newUser) {
        this.search = null;
        this.model = null;
        this.editUser = false;
        this.resetSearchResults();
        this.resetAll();
      }
    }
  },
  computed: {
    ...mapState({
      entries: state => state.form.searchResults
    }),
    currentStep: {
      get() {
        return this.$store.state.form.step;
      }
    },
    lang: {
      get() {
        return this.$store.state.form.lang;
      }
    },
    items() {
      return [
        this.$t("menu1"),
        this.$t("menu2"),
        this.$t("menu3"),
        this.$t("menu4"),
        this.$t("menu5")
      ];
    },
    users() {
      if (this.entries) {
        return this.entries.map(entry => {
          const Result = entry.name + " - " + entry.cell;
          return Object.assign({}, entry, { Result });
        });
      } else {
        return [];
      }
    },
    newUser: {
      get() {
        return this.$store.state.form.newUser;
      },
      set(value) {
        this.$store.commit("setNewUser", value);
      }
    },
    editUser: {
      get() {
        return this.$store.state.form.editUser;
      },
      set(value) {
        this.$store.commit("setEditUser", value);
      }
    },
    clear: {
      get() {
        return this.$store.state.form.clear;
      },
      set(value) {
        this.$store.commit("setClear", value);
      }
    }
  },
  methods: {
    ...mapActions(["phoneSearch", "phoneCheck", "logout"]),
    ...mapMutations([
      "resetSearchResults",
      "setUserId",
      "setCell",
      "setFirstName",
      "setLastName",
      "setStep",
      "setLang",
      "resetAll",
      "setClear",
      "setBirthday",
      "setGender",
      "setEmail",
      "setAddress",
      "setZipCode",
      "setIsSmoker",
      "setHasPathologies",
      "setKneeProblem",
      "setShoulderProblem",
      "setBackProblem",
      "setSkeletalProblem",
      "setHealthInfo",
      "setWantsPersonalTrainer",
      "setDoesWorkout",
      "setYearsOfWorkout",
      "setExerciseType",
      "setExerciseDays",
      "setSelectedFoodsCon",
      "setSelectedMedicalConditions",
      "setSelectedNonClinical"
    ]),
    setCurrentStep: function(value) {
      let newCurrentStep = value + 1;
      this.setStep({ step: newCurrentStep });
      this.$router.push("/step" + newCurrentStep);
    },
    clearForm() {
      this.newUser = true;
      this.search = null;
      this.model = null;
      this.resetSearchResults();
      this.resetAll();
      this.clear = true;
      this.clear = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.selectedItem {
  color: #00ffff !important;
}
.v-list-item__content {
  border-bottom: 1px solid white;
}

.lang-row {
  position: fixed;
  bottom: 0;
  margin-left: 15px;
}

.theme--dark.v-sheet {
  background-color: #565c72;
  border-color: #1e1e1e;
  text-transform: capitalize;
  color: #565c72 !important;
}

.expandBtn {
  top: 10px !important;
}

#input-9 {
  color: #ffffff !important;
}

#newUsr:hover {
  .v-icon {
    color: #00ffff !important;
  }
  color: #00ffff !important;
}
</style>
