<template>
    <div @click="$router.push('/step1')">
      <div class="imgTxt1">
        <img
            src="@/assets/img/myflex.png"
            alt="logo"
            width="50%"
            height="50%"
          />
      </div>
      <div class="imgTxt2">{{ $t("thankYouMsg2") }}</div>
      <div class="header-container">
          <svg viewBox="0 0 500 500"
              preserveAspectRatio="xMinYMin meet"
              style="z-index: -2;">
                
              <path d="M0, 75 C250, 0 350,
                  250 500, 50 L500, 00 L0, 0 Z"
                  style="stroke: none;
                  fill:rgb(255 255 255 / 54%);">
              </path>
          </svg>
      </div>
      <div class="header-container">
          <svg viewBox="0 0 500 500"
              preserveAspectRatio="xMinYMin meet"
              style="z-index:-1;">
                
              <path d="M0, 80 C300, 0 400,
                  300 500, 50 L500, 00 L0, 0 Z"
                  style="stroke: none;
                  fill:rgb(255 255 255 / 50%);">
              </path>
          </svg>
      </div>
      <div class="header-container">
          <svg viewBox="0 0 500 500"
              preserveAspectRatio="xMinYMin meet"
              style="z-index:-3;">
                
              <path d="M0, 78 C270, 0 370,
                  270 500, 50 L500, 00 L0, 0 Z"
                  style="stroke: none;
                  fill:rgb(255 255 255 / 41%);">
              </path>
          </svg>
      </div>
      <span class="img-background"></span>
      <span class="bg-wrapper">

        <div class="d-flex flex-column flex-grow-1">
          <v-btn
            class="ma-4 align-self-end nextBtn tyBtn"
            plain
            :right="true"
            @click="$router.push('/step1')"
          >
            <v-icon right x-large style="font-size:80px" class="px-6"
              >mdi-chevron-right-circle-outline</v-icon
            >
          </v-btn>
        </div>
      </span>
      
    </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  data() {
    return {};
  },
  methods: {
    ...mapMutations(["resetAll"])
  },
  created() {
    this.resetAll();
  }
};
</script>

<style scoped lang="scss">
bg-wrapper {
  position: relative;
  text-align: center;
  color: white;
}

svg {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
}
.header-container {
    display: inline-block;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    position: fixed;
    z-index: 9999;
    overflow: hidden;
    top: -70px;
    left: 0;
}
body {
    overflow: hidden;
}

.img-background {
  background: url("~@/assets/img/shutterstock_1638409804-scaled.jpg") no-repeat center center !important;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover !important;
  background-color: rgb(9, 69, 144) !important;
  transform: scale(1.1);
}


.imgTxt1 {
  position: absolute;
  z-index: 99999999;

  img {
    filter: invert(48%) sepia(2%) saturate(3207%) hue-rotate(183deg) brightness(95%) contrast(80%);
  }
}

.imgTxt2 {
  width: 100%;
  color: #565c72;
  position: absolute;
  top: 35px;
  font-family: ArtegraSansExtended-Regular, sans-serif;
  font-size: 1em;
  letter-spacing: 0.1em;
  text-align: right;
  z-index: 99999999;
  text-transform: unset !important;
}

.tyBtn {
  position: fixed;
  bottom: 80px;
}
</style>
