import axios from "axios";
import router from "@/router";

export default {
  state: {
    token: localStorage.getItem("token") || ""
  },
  getters: {
    isLoggedIn: state => !!state.token
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
      localStorage.setItem("token", token);
      axios.defaults.headers.common["Authorization"] = `JWT ${token}`;
    },
    logout(state) {
      state.token = "";
      localStorage.removeItem("token");
      delete axios.defaults.headers.common["Authorization"];
    }
  },
  actions: {
    login({ commit }, user) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BASE_URL}/api/user/login`, {
            ...user
          })
          .then(res => {
            const token = res.data.data._token;
            commit("setToken", token);
          })
          .then(() => {
            resolve();
          })
          .catch(err => {
            commit("logout");
            reject(err);
          });
      });
    },
    logout({ commit }) {
      commit("logout");
      router.push({ path: "/login" });
    }
  }
};
