<template>
  <v-form
    v-model="valid"
    ref="form"
    :disabled="
      this.$store.state.form.cell.length == 0 && !this.$store.state.form.newUser
    "
  >
    <v-sheet color="#565c72" rounded>
      <h1 class="section-title text-capitalize">
        {{ $t("basicInfo") }}
      </h1>
    </v-sheet>
    <v-row>
      <v-col cols="12" lg="10">
        <v-sheet color="#eaeaea" class="fieldgroup">
          <p>{{ $t("basicInfoTxt") }}</p>

          <v-text-field
            :label="$t('basicInfoField1')"
            :rules="lastNameRules"
            autocomplete="family-name"
            hide-details="auto"
            validate-on-blur
            v-model="lastName"
          >
          </v-text-field>

          <v-text-field
            :label="$t('basicInfoField2')"
            :rules="firstNameRules"
            autocomplete="given-name"
            hide-details="auto"
            validate-on-blur
            v-model="firstName"
          >
          </v-text-field>
          <v-menu
            ref="dateMenu"
            v-model="dateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                :label="$t('basicInfoField3')"
                readonly
                hide-details
                :rules="[v => !!v || $t('error1')]"
                hint="MM/DD/YYYY format"
                persistent-hint
                v-model="birthdayDate"
                v-on="on"
                @blur="date = parseDate(birthdayDate)"
              ></v-text-field>
            </template>
            <v-date-picker
              :locale="locale"
              ref="picker"
              no-title
              v-model="date"
              :max="new Date().toISOString().substr(0, 10)"
              min="1950-01-01"
              @input="dateMenu = false"
            ></v-date-picker>
          </v-menu>
          <v-radio-group
            v-model="gender"
            :rules="[v => !!v || $t('error2')]"
            :label="$t('basicInfoField4')"
            row
          >
            <v-radio :label="$t('basicInfoField5')" value="1"></v-radio>
            <v-radio :label="$t('basicInfoField6')" value="2"></v-radio>
          </v-radio-group>
        </v-sheet>
      </v-col>
    </v-row>
    <v-sheet color="#565c72" rounded>
      <h1 class="section-title text-capitalize">{{ $t("basicInfo2") }}</h1>
    </v-sheet>

    <v-row>
      <v-col cols="12" lg="10">
        <v-sheet color="#eaeaea" class="fieldgroup">
          <!-- <v-text-field
            :label="$t('basicInfoField7')"
            :disabled="
              !this.$store.state.form.newUser &&
                !this.$store.state.form.editUser
            "
            hide-details="auto"
            v-model="phone"
            :rules="phoneRules"
            validate-on-blur
            @change="validatePhone"
          >
          </v-text-field> -->
          <vue-tel-input-vuetify
            :defaultCountry="'GR'"
            :label="$t('vueTelPlaceholder')"
            @onInput="telInput"
            @country-changed="countryChanged"
            v-model="phone"
            :rules="phoneRules"
          ></vue-tel-input-vuetify>
          <v-text-field
            :label="$t('basicInfoField8')"
            :rules="emailRules"
            autocomplete="email"
            hide-details="auto"
            validate-on-blur
            v-model="email"
          >
          </v-text-field>

          <v-text-field
            :label="$t('basicInfoField9')"
            autocomplete="shipping street-address"
            hide-details="auto"
            validate-on-blur
            v-model="address"
          >
          </v-text-field>

          <v-text-field
            :label="$t('basicInfoField10')"
            autocomplete="shipping billing-info"
            hide-details="auto"
            validate-on-blur
            v-model="zip_code"
            :rules="[v => !!v || $t('error1')]"
          >
          </v-text-field>
        </v-sheet>
      </v-col>
    </v-row>

    <v-sheet v-show="false" color="#565c72" rounded>
      <h1 class="section-title text-capitalize">{{ $t("basicInfo3") }}</h1>
    </v-sheet>

    <v-row v-show="false">
      <v-col cols="12" lg="10">
        <v-sheet color="#eaeaea" class="fieldgroup">
          <p>{{ $t("basicInfoTxt2") }}</p>

          <v-autocomplete
            v-if="!recommenderName"
            v-model="recommender"
            outlined
            dense
            app
            :items="users"
            :loading="isLoading"
            hide-no-data
            item-text="Result"
            item-value="name"
            class="flex-grow-0 pa-4 search-input"
            append-icon="mdi-magnify"
            :search-input.sync="search"
            return-object
          >
          </v-autocomplete>

          <i v-if="recommenderName"
            >{{ $t("basicInfoTxt3") }} {{ recommenderName }}</i
          >
        </v-sheet>
      </v-col>
    </v-row>

    <div class="d-flex flex-column flex-grow-1">
      <v-btn
        class="ma-4 align-self-end nextBtn"
        plain
        :right="true"
        @click="validate"
      >
        {{ $t("next") }}
        <v-icon right class="nextIcon"> mdi-chevron-right</v-icon>
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import { mapMutations, mapActions, mapState } from "vuex";
export default {
  watch: {
    dateMenu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    date() {
      this.birthdayDate = this.formatDate(this.date);
    },
    valid() {
      this.setStep1Valid({ step1Valid: this.valid });
    },
    clear() {
      if (this.clear) {
        this.resetAll();
      }
    },
    recommender() {
      this.search = "";
      console.log(this.recommender);
      this.setRecommenderId({ recommenderId: this.recommender.id });

      this.recommenderName = this.recommender.name;
      console.log(this.recommenderName);
    },
    search() {
      if (this.search == null || this.search == "") {
        this.resetSearchRecommenderResults();
      }
      // if (this.users != null && this.users.length > 0) return;

      if (this.isLoading) return;

      this.isLoading = true;

      if (this.search != null && this.search.length >= 6) {
        this.recommenderSearch(this.search);
      }
      this.isLoading = false;
    }
  },
  data: () => ({
    dateMenu: false,
    date: null,
    valid: false,
    search: null,
    recommender: null,
    recommenderName: null,
    isLoading: false
  }),
  created() {
    this.setStep({ step: 1 });
  },
  methods: {
    ...mapMutations([
      "setStep",
      "resetSearchRecommenderResults",
      "setRecommenderId",
      "setStep1Valid",
      "setPhoneExists",
      "setPhoneRequestExists",
      "resetAll"
    ]),
    ...mapActions(["phoneCheck", "recommenderSearch"]),
    validate() {
      if (
        this.$refs.form.validate() &&
        this.phoneExists == false &&
        this.phoneRequestExists == false
      ) {
        this.setStep({ step: 2 });
        this.setStep1Valid({ step1Valid: true });
        this.$router.push("/step2");
      } else {
        const that = this;

        setTimeout(function() {
          that.$el.querySelector(".error--text").scrollIntoView({
            behavior: "smooth"
          });
        }, 1000);
      }
    },
    validatePhone() {
      this.phoneCheck(this.phone);
    },
    blurDate() {
      setTimeout(() => {
        if (this.dateMenu) {
          this.dateMenu = false;
        }
      }, 200);
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    telInput({ number, isValid, country }) {
      console.log(number);
      console.log(isValid);
      console.log(country);
      if (this.phone.length) {
        this.phone = number.e164;
        this.phoneCheck({
          number: number.significant,
          country_code: country.dialCode
        });
      }
    },
    countryChanged(country) {
      console.log(country);
      // this.phone = '+' + country.dialCode
    }
  },
  computed: {
    ...mapState({
      entries: state => state.form.searchRecommenderResults
    }),

    locale: function() {
      if (this.$i18n.locale == "el") {
        return "el-GR";
      } else {
        return "en-GB";
      }
    },
    clear: {
      get() {
        return this.$store.state.form.clear;
      }
    },
    firstName: {
      get() {
        return this.$store.state.form.firstName;
      },
      set(value) {
        this.$store.commit("setFirstName", { firstName: value });
      }
    },
    lastName: {
      get() {
        return this.$store.state.form.lastName;
      },
      set(value) {
        this.$store.commit("setLastName", { lastName: value });
      }
    },
    gender: {
      get() {
        return this.$store.state.form.gender;
      },
      set(value) {
        this.$store.commit("setGender", { gender: value });
      }
    },
    birthdayDate: {
      get() {
        return this.$store.state.form.birthday;
      },
      set(value) {
        this.$store.commit("setBirthday", { birthday: value });
      }
    },
    phone: {
      get() {
        return this.$store.state.form.cell;
      },
      set(value) {
        this.$store.commit("setCell", { cell: value });
      }
    },
    ...mapState({
      phoneExists: state => state.form.phoneExists,
      phoneRequestExists: state => state.form.phoneRequestExists,
      newUser: state => state.form.newUser
    }),
    address: {
      get() {
        return this.$store.state.form.address;
      },
      set(value) {
        this.$store.commit("setAddress", { address: value });
      }
    },
    zip_code: {
      get() {
        return this.$store.state.form.zip_code;
      },
      set(value) {
        this.$store.commit("setZipCode", { zip_code: value });
      }
    },
    email: {
      get() {
        return this.$store.state.form.email;
      },
      set(value) {
        this.$store.commit("setEmail", { email: value });
      }
    },
    users() {
      if (this.entries) {
        return this.entries.map(entry => {
          const Result = entry.name + " - " + entry.cell;
          return Object.assign({}, entry, { Result });
        });
      } else {
        return [];
      }
    },
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
    firstNameRules() {
      return [
        value => !!value || this.$t("error1"),
        value => (value && value.length >= 3) || this.$t("error3")
      ];
    },
    lastNameRules() {
      return [
        value => !!value || this.$t("error1"),
        value => (value && value.length >= 3) || this.$t("error3")
      ];
    },
    emailRules() {
      return [
        value => !!value || this.$t("error1"),
        value =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            value
          ) || this.$t("error5")
      ];
    },
    phoneRules() {
      return [
        value => !!value || this.$t("error1"),
        // value => (value && value.length == 10) || this.$t("error4"),
        this.phoneExists == false || this.$t("error7"),
        this.phoneRequestExists == false || this.$t("error8")
      ];
    }
  }
};
</script>
<style lang="scss" scoped>
.section-title {
  color: white;
  padding-left: 50px;
  margin: 10px 0;
}
.fieldgroup {
  padding: 20px;
}

.v-text-field {
  padding-bottom: 18px;
}

.v-menu__content {
  min-width: 290px !important;
}
</style>
