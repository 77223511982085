import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  customVariables: ["~/assets/sass/variables.scss"],
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: "#11D3B3",
        secondary: "#1B0768",
        accent: "#565c72",
        error: "#DC5B00"
      }
    },
    options: {
      customProperties: true
    }
  }
});
