<template>
  <v-form
    v-model="valid"
    :disabled="
      this.$store.state.form.cell.length == 0 && !this.$store.state.form.newUser
    "
  >
    <v-sheet color="#565c72" rounded>
      <h1 class="section-title text-capitalize">{{ $t("menu3") }}</h1>
    </v-sheet>
    <v-row>
      <v-col cols="12" lg="8">
        <v-sheet color="#eaeaea" class="fieldgroup">
          <h4>{{ $t("exerciseHistoryField1") }}</h4>
          <v-radio-group
            v-model="doesWorkout"
            elevation="2"
            :rules="[v => !!v || $t('error2')]"
            row
          >
            <v-radio
               
              :label="$t('no')"
              value="0"
              @click="clearForm"
            ></v-radio>
            <v-radio
               
              :label="$t('yes')"
              value="1"
            ></v-radio>
          </v-radio-group>
        </v-sheet>

        <v-sheet color="#eaeaea" class="fieldgroup" v-show="doesWorkout!=0">
          <h4>{{ $t("exerciseHistoryField2") }}</h4>
          <v-col class="d-flex" cols="4" sm="4">
            <v-select
              v-model="yearsOfWorkout"
              :items="yearsOfWorkoutOptions"
              :disabled="doesWorkout == 0"
              :rules="doesWorkout == 0 ? [] : [v => !!v || $t('error2')]"
              outlined
              dense
              item-color="accent"
            ></v-select>
          </v-col>
        </v-sheet>
        <v-sheet color="#eaeaea" class="fieldgroup" v-show="doesWorkout!=0">
          <h4>{{ $t("exerciseHistoryField3") }}</h4>
          <v-radio-group
            v-model="wantsPersonalTrainer"
            :disabled="doesWorkout == 0"
            :rules="doesWorkout == 0 ? [] : [v => !!v || $t('error2')]"
          >
            <v-radio
              :label="$t('exerciseHistoryField3Opt1')"
              value="0"
            ></v-radio>
            <v-radio
              :label="$t('exerciseHistoryField3Opt2')"
              value="1"
            ></v-radio>
          </v-radio-group>
        </v-sheet>
        <v-sheet color="#eaeaea" class="fieldgroup" v-show="doesWorkout!=0">
          <h4>{{ $t("exerciseHistoryField4") }}</h4>
          <v-radio-group
            v-model="exerciseType"
            :disabled="doesWorkout == 0"
            :rules="doesWorkout == 0 ? [] : [v => !!v || $t('error2')]"
          >
            <v-radio
              :label="$t('exerciseHistoryField4Opt1')"
              value="1"
              :key="1"
              name="exerciseType"
            ></v-radio>
            <v-radio
              :label="$t('exerciseHistoryField4Opt2')"
              value="2"
              :key="2"
              name="exerciseType"
            ></v-radio>
            <v-radio
              :label="$t('exerciseHistoryField4Opt3')"
              value="3"
              :key="3"
              name="exerciseType"
            ></v-radio>

            <v-radio
              :label="$t('exerciseHistoryField4Opt4')"
              value="4"
              :key="4"
              name="exerciseType"
            ></v-radio>
            <v-text-field
              v-show="exerciseType==4"
              :label="$t('exerciseHistoryField4Opt5')"
              v-model="exerciseTypeOther"
              :key="5"
              name="exerciseTypeOther"
            ></v-text-field>
          </v-radio-group>
        </v-sheet>
        <v-sheet color="#eaeaea" class="fieldgroup" v-show="doesWorkout!=0">
          <h4>{{ $t("exerciseHistoryField5") }}</h4>
          <v-select
            v-model="exerciseDays"
            :disabled="doesWorkout == 0"
            :items="exerciseDaysOptions"
            :menu-props="{ maxHeight: '300' }"
            :rules="doesWorkout == 0 ? [] : [v => !!v || $t('error2')]"
          ></v-select>
        </v-sheet>
      </v-col>
    </v-row>
    <div class="d-flex flex-column flex-grow-1">
      <v-btn
        :disabled="!valid"
        class="ma-4 align-self-end nextBtn"
        plain
        :right="true"
        @click="validate"
      >
        {{ $t("next") }}
        <v-icon right class="nextIcon"> mdi-chevron-right</v-icon>
      </v-btn>
    </div>
  </v-form>
</template>

<script>
export default {
  watch: {
    valid() {
      this.$store.commit("setStep3Valid", { step3Valid: this.valid });
    }
  },
  data() {
    return {
      valid: false,
      yearsOfWorkoutOptions: [
        "< 1 (" + this.$t("lessThanOneYearText") + ")",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6+"
      ],
      exerciseDaysOptions: ["1", "2", "3", "4", "5", "6", "7"]
    };
  },
  methods: {
    validate() {
      //this.$refs.form.validate()
      this.$store.commit("setStep", { step: 4 });
      this.$store.commit("setStep3Valid", { step3Valid: true });
      this.$router.push("/step4");
    },
    clearForm() {
      this.yearsOfWorkout = null;
      this.wantsPersonalTrainer = null;
      this.exerciseType = null;
      this.exerciseTypeOther = null;
      this.exerciseDays = null;
    }
  },
  created() {
    this.$store.commit("setStep", { step: 3 });
  },
  computed: {
    doesWorkout: {
      get() {
        return this.$store.state.form.doesWorkout;
      },
      set(value) {
        this.$store.commit("setDoesWorkout", { doesWorkout: value });
      }
    },
    yearsOfWorkout: {
      get() {
        return this.$store.state.form.yearsOfWorkout;
      },
      set(value) {
        this.$store.commit("setYearsOfWorkout", { yearsOfWorkout: value });
      }
    },
    wantsPersonalTrainer: {
      get() {
        return this.$store.state.form.wantsPersonalTrainer;
      },
      set(value) {
        this.$store.commit("setWantsPersonalTrainer", {
          wantsPersonalTrainer: value
        });
      }
    },
    exerciseType: {
      get() {
        return this.$store.state.form.exerciseType;
      },
      set(value) {
        this.$store.commit("setExerciseType", {
          exerciseType: value
        });
        console.log(value);
      }
    },
    exerciseTypeOther: {
      get() {
        return this.$store.state.form.exerciseTypeOther;
      },
      set(value) {
        this.$store.commit("setExerciseTypeOther", {
          exerciseTypeOther: value
        });
        console.log(value);
      }
    },
    exerciseDays: {
      get() {
        return this.$store.state.form.exerciseDays;
      },
      set(value) {
        this.$store.commit("setExerciseDays", {
          exerciseDays: value
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.section-title {
  color: white;
  padding-left: 50px;
  margin: 10px 0;
}
.fieldgroup {
  padding: 20px 20px 0 20px;
  margin-bottom: 10px;
}
</style>
