import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

import LogIn from "@/components/Login.vue";
import BasicInfo from "@/components/BasicInfo.vue";
import MedicalHistory from "@/components/MedicalHistory.vue";
import ExerciselHistory from "@/components/ExerciselHistory.vue";
import FoodQuestionnaire from "@/components/FoodQuestionnaire.vue";
import Agreement from "@/components/Agreement.vue";
import ThankYou from "@/components/ThankYou.vue";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

const routes = [
  {
    path: "*",
    redirect: "/step1"
  },
  {
    path: "/login",
    name: "login",
    component: LogIn,
    meta: {
      ifNotAuthenticated: true
    }
  },
  {
    path: "/step1",
    name: "step1",
    component: BasicInfo,
    meta: {
      ifAuthenticated: true
    }
  },
  {
    path: "/step2",
    name: "step2",
    component: MedicalHistory,
    meta: {
      ifAuthenticated: true
    }
  },
  {
    path: "/step3",
    name: "step3",
    component: ExerciselHistory,
    meta: {
      ifAuthenticated: true
    }
  },
  {
    path: "/step4",
    name: "step4",
    component: FoodQuestionnaire,
    meta: {
      ifAuthenticated: true
    }
  },
  {
    path: "/step5",
    name: "step5",
    component: Agreement,
    meta: {
      ifAuthenticated: true
    }
  },
  {
    path: "/finish",
    name: "finish",
    component: ThankYou,
    meta: {
      ifAuthenticated: true
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

// eslint-disable-next-line no-unused-vars
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  next();
});

router.beforeEach((to, from, next) => {
  if (
    to.matched.some(record => record.meta.ifAuthenticated) &&
    !store.getters.isLoggedIn
  ) {
    next({ path: "login" });
    return;
  }

  if (
    to.matched.some(record => record.meta.ifNotAuthenticated) &&
    store.getters.isLoggedIn
  ) {
    next({ path: "step1" });
    return;
  }

  next();
});

export default router;
