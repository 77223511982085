<template>
  <v-form
    v-model="valid"
    ref="medical_form"
    :disabled="
      this.$store.state.form.cell.length == 0 && !this.$store.state.form.newUser
    "
  >
    <v-sheet color="#565c72" rounded>
      <h1 class="section-title text-capitalize">{{ $t("menu2") }}</h1>
    </v-sheet>
    <v-row>
      <v-col cols="12" lg="8">
        <v-sheet color="#eaeaea" class="fieldgroup">
          <h4>{{ $t("healthHistoryField1") }}</h4>
          <v-radio-group
            v-model="isSmoker"
            elevation="2"
            :rules="[v => !!v || $t('error2')]"
            row
          >
            <v-radio
               
              :label="$t('no')"
              value="0"
              :key="0"
            ></v-radio>
            <v-radio
               
              :label="$t('yes')"
              value="1"
              :key="1"
            ></v-radio>
            <v-radio :label="$t('smoker')" value="3" :key="3"></v-radio>
          </v-radio-group>
        </v-sheet>

        <v-sheet color="#eaeaea" class="fieldgroup">
          <h4>{{ $t("healthHistoryField2") }}</h4>
          <v-radio-group
            v-model="hasPathologies"
            :rules="[v => !!v || $t('error2')]"
            row
          >
            <v-radio
               
              :label="$t('no')"
              value="0"
            ></v-radio>
            <v-radio
               
              :label="$t('yes')"
              value="1"
            ></v-radio>
          </v-radio-group>
        </v-sheet>
        <v-sheet
          v-show="hasPathologies == 1"
          color="#eaeaea"
          elevation="3"
          class="fieldgroup"
        >
          <h4>{{ $t("healthHistoryFieldConditions") }}</h4>

          <v-checkbox
            v-model="medicalConditionsSelections"
            v-for="(medicalCondition, index) in medicalConditionsOptions"
            :key="index"
            :label="medicalCondition.name"
            :value="medicalCondition.id"
          ></v-checkbox>
        </v-sheet>
        <v-sheet color="#eaeaea" class="fieldgroup">
          <h4>{{ $t("healthHistoryField3") }}</h4>
          <v-radio-group
            v-model="kneeProblem"
            :rules="[v => !!v || $t('error2')]"
            row
          >
            <v-radio
               
              :label="$t('no')"
              value="0"
            ></v-radio>
            <v-radio
               
              :label="$t('yes')"
              value="1"
            ></v-radio>
          </v-radio-group>
        </v-sheet>
        <v-sheet color="#eaeaea" class="fieldgroup">
          <h4>{{ $t("healthHistoryField4") }}</h4>
          <v-radio-group
            v-model="shoulderProblem"
            :rules="[v => !!v || $t('error2')]"
            row
          >
            <v-radio
               
              :label="$t('no')"
              value="0"
            ></v-radio>
            <v-radio
               
              :label="$t('yes')"
              value="1"
            ></v-radio>
          </v-radio-group>
        </v-sheet>
        <v-sheet color="#eaeaea" class="fieldgroup">
          <h4>{{ $t("healthHistoryField5") }}</h4>
          <v-radio-group
            v-model="backProblem"
            :rules="[v => !!v || $t('error2')]"
            row
          >
            <v-radio
               
              :label="$t('no')"
              value="0"
            ></v-radio>
            <v-radio
               
              :label="$t('yes')"
              value="1"
            ></v-radio>
          </v-radio-group>
        </v-sheet>
        <v-sheet color="#eaeaea" class="fieldgroup">
          <h4>{{ $t("healthHistoryField6") }}</h4>
          <v-radio-group
            v-model="skeletalProblem"
            :rules="[v => !!v || $t('error2')]"
            row
          >
            <v-radio
               
              :label="$t('no')"
              value="0"
            ></v-radio>
            <v-radio
               
              :label="$t('yes')"
              value="1"
            ></v-radio>
          </v-radio-group>
        </v-sheet>
        <v-sheet color="#eaeaea" class="fieldgroup">
          <h4>
            {{ $t("healthHistoryField7") }}
          </h4>
          <v-text-field label="" hide-details="auto" v-model="healthInfo">
          </v-text-field>
        </v-sheet>
      </v-col>
    </v-row>
    <div class="d-flex flex-column flex-grow-1">
      <v-btn
        class="ma-4 align-self-end nextBtn"
        plain
        :right="true"
        @click="validate"
      >
        {{ $t("next") }}
        <v-icon right class="nextIcon">mdi-chevron-right</v-icon>
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import { mapMutations, mapActions } from "vuex";
export default {
  watch: {
    valid() {
      this.setStep2Valid({ step2Valid: this.valid });
    },
    lang() {
      this.resetMedicalConditionsOptions();
      this.getMedicalConditions(this.lang);
    }
  },
  data: () => ({
    valid: false
  }),
  created() {
    this.setStep({ step: 2 });
    this.getMedicalConditions(this.lang);
  },
  methods: {
    ...mapMutations(["setStep", "setStep2Valid"]),
    ...mapActions(["getMedicalConditions", "resetMedicalConditionsOptions"]),

    validate() {
      if (this.$refs.medical_form.validate()) {
        this.setStep({ step: 3 });
        this.setStep2Valid({ step2Valid: true });
        this.$router.push("/step3");
      }
      else {
        const that = this;
        setTimeout(function() {
          that.$el.querySelector(".error--text").scrollIntoView({
              behavior: 'smooth',
            });
        }, 1000);
      }
    }
  },
  computed: {
    medicalConditionsOptions: {
      get() {
        return this.$store.state.form.medicalConditionsOptions;
      }
    },
    medicalConditionsSelections: {
      get() {
        return this.$store.state.form.selectedMedicalConditions;
      },
      set(value) {
        this.$store.commit("setSelectedMedicalConditions", {
          selectedMedicalConditions: value
        });
      }
    },
    isSmoker: {
      get() {
        return this.$store.state.form.isSmoker;
      },
      set(value) {
        this.$store.commit("setIsSmoker", { isSmoker: value });
      }
    },
    hasPathologies: {
      get() {
        return this.$store.state.form.hasPathologies;
      },
      set(value) {
        this.$store.commit("setHasPathologies", { hasPathologies: value });
      }
    },
    kneeProblem: {
      get() {
        return this.$store.state.form.kneeProblem;
      },
      set(value) {
        this.$store.commit("setKneeProblem", { kneeProblem: value });
      }
    },
    shoulderProblem: {
      get() {
        return this.$store.state.form.shoulderProblem;
      },
      set(value) {
        this.$store.commit("setShoulderProblem", { shoulderProblem: value });
      }
    },
    backProblem: {
      get() {
        return this.$store.state.form.backProblem;
      },
      set(value) {
        this.$store.commit("setBackProblem", { backProblem: value });
      }
    },
    skeletalProblem: {
      get() {
        return this.$store.state.form.skeletalProblem;
      },
      set(value) {
        this.$store.commit("setSkeletalProblem", { skeletalProblem: value });
      }
    },
    healthInfo: {
      get() {
        return this.$store.state.form.healthInfo;
      },
      set(value) {
        this.$store.commit("setHealthInfo", { healthInfo: value });
      }
    },
    lang: {
      get() {
        return this.$store.state.form.lang;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.section-title {
  color: white;
  padding-left: 50px;
  margin: 10px;
}
.fieldgroup {
  padding: 20px 20px 0 20px;
  margin-bottom: 10px;
}
</style>
