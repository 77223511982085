<template>
  <v-form v-model="valid">
    <v-sheet color="#565c72" rounded>
      <h1 class="section-title text-capitalize">{{ $t("menu5") }}</h1>
    </v-sheet>
    <v-divider class="divider-lg"></v-divider>
    <v-row>
      <v-col cols="12">
        <v-sheet color="#eaeaea" class="fieldgroup">
          <v-sheet color="#11d3b3" rounded>
            <h2 class="section-title">{{ $t("moreInfohd1") }}</h2>
          </v-sheet>
          <p class="pt-2 px-4">
            {{ $t("moreInfoP11") }}
          </p>
          <p class="px-4">
            {{ $t("moreInfoP12") }}
          </p>
          <v-divider></v-divider>
          <p class="pt-4 px-4">
            {{ $t("moreInfoP13") }}
          </p>

          <p class="px-4">
            {{ $t("moreInfoP14") }}
          </p>
        </v-sheet>
        <v-sheet color="#eaeaea" class="fieldgroup mt-4">
          <v-sheet color="#11d3b3" rounded>
            <h2 class="section-title">{{ $t("moreInfohd2") }}</h2>
          </v-sheet>
          <p class="pt-2 px-4">
            {{ $t("moreInfoP21") }}
          </p>
          <p class="px-4">
            {{ $t("moreInfoP22") }}
          </p>
          <v-divider></v-divider>
          <p class="pt-4 px-4">
            {{ $t("moreInfoP23") }}
          </p>

          <p class="px-4">
            {{ $t("moreInfoP24") }}
          </p>
        </v-sheet>
      </v-col>
    </v-row>
    <div class="d-flex flex-row flex-grow-1 justify-space-between">
      <p class="align-self-center red--text" v-if="missingSteps.length > 0">
        {{ $t("error6") }}
        <span v-if="$i18n.locale == 'el'">
          {{ missingSteps[0] }}
          {{ missingSteps[2] }}
          {{ missingSteps[4] }}
          {{ missingSteps[6] }}
        </span>
        <span v-else>
          {{ missingSteps[1] }}
          {{ missingSteps[3] }}
          {{ missingSteps[5] }}
          {{ missingSteps[7] }}</span
        >
      </p>

      <v-btn
        :disabled="!valid"
        class="ma-4 align-self-end nextBtn ml-auto"
        plain
        :right="true"
        @click="validate"
      >
        {{ $t("submit") }}
        <v-icon right large color="success" class="pl-2"
          >mdi-check-circle</v-icon
        >
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  data: () => ({
    valid: false,
    missingSteps: []
  }),
  created() {
    this.$store.commit("setStep", { step: 5 });
  },
  methods: {
    ...mapActions(["updateUserDetails"]),
    validate() {
      if (
        (this.step1Valid &&
          this.step2Valid &&
          this.step3Valid &&
          this.step4Valid) ||
        this.$store.state.form.editUser
      ) {
        this.updateUserDetails();
        this.$router.push("/finish");
      } else {
        if (!this.step1Valid) {
          this.missingSteps.push("Προφίλ");
          this.missingSteps.push("Profile");
        }
        if (!this.step2Valid) {
          this.missingSteps.push("Ιστορικό υγείας");
          this.missingSteps.push("Medical History");
        }
        if (!this.step3Valid) {
          this.missingSteps.push("Ιστορικό Άσκησης");
          this.missingSteps.push("Exercise History");
        }
        if (!this.step4Valid) {
          this.missingSteps.push("Ερωτηματολόγιο");
          this.missingSteps.push("Questionnaire");
        }
      }
    }
  },
  computed: {
    ...mapState({
      step1Valid: state => state.form.step1Valid,
      step2Valid: state => state.form.step2Valid,
      step3Valid: state => state.form.step3Valid,
      step4Valid: state => state.form.step4Valid
    })
  }
};
</script>
<style lang="scss" scoped>
.section-title {
  color: white;
  padding-left: 50px;
  margin: 10px 0;
}
.fieldgroup {
  padding: 20px 0px;
}

.divider-lg {
  border-width: thick;
  border-color: white;
  margin-bottom: 15px;
}

</style>
