import axios from "axios";

export default {
  state: {
    step: 1,
    lang: "el",
    clear: false,
    newUser: false,
    editUser: false,
    phoneExists: false,
    phoneRequestExists: false,
    step1Valid: false,
    step2Valid: false,
    step3Valid: false,
    step4Valid: false,
    userId: null,
    recommenderId: null,
    lastName: "",
    firstName: "",
    birthday: "",
    gender: null,
    cell: "",
    email: "",
    address: "",
    zip_code: "",
    isSmoker: null,
    hasPathologies: null,
    kneeProblem: null,
    shoulderProblem: null,
    backProblem: null,
    skeletalProblem: null,
    healthInfo: "",
    doesWorkout: null,
    yearsOfWorkout: null,
    wantsPersonalTrainer: null,
    exerciseType: null,
    exerciseTypeOther: null,
    exerciseDays: "",
    selectedFoods: [],
    selectedFoodsCon: [],
    selectedNonClinical: [],
    selectedMedicalConditions: [],
    searchResults: [],
    searchRecommenderResults: [],
    questionnaireOptions: [],
    nonClinicalOptions: [],
    nonClinicalSelections: [],
    medicalConditionsOptions: [],
  },
  mutations: {
    setStep(state, payload) {
      state.step = payload.step;
    },
    setLang(state, payload) {
      state.lang = payload.lang;
    },
    setNewUser(state, val) {
      state.newUser = val;
    },
    setEditUser(state, val) {
      state.editUser = val;
    },
    setClear(state, val) {
      state.clear = val;
    },
    setPhoneExists(state, val) {
      state.phoneExists = val;
    },
    setPhoneRequestExists(state, val) {
      state.phoneRequestExists = val;
    },
    setStep1Valid(state, payload) {
      state.step1Valid = payload.step1Valid;
    },
    setStep2Valid(state, payload) {
      state.step2Valid = payload.step2Valid;
    },
    setStep3Valid(state, payload) {
      state.step3Valid = payload.step3Valid;
    },
    setStep4Valid(state, payload) {
      state.step4Valid = payload.step4Valid;
    },
    setUserId(state, payload) {
      state.userId = payload.userId;
    },
    setRecommenderId(state, payload) {
      state.recommenderId = payload.recommenderId;
    },
    setLastName(state, payload) {
      state.lastName = payload.lastName;
    },
    setFirstName(state, payload) {
      state.firstName = payload.firstName;
    },
    setBirthday(state, payload) {
      state.birthday = payload.birthday;
    },
    setGender(state, payload) {
      state.gender = payload.gender;
    },
    setCell(state, payload) {
      state.cell = payload.cell;
    },
    setEmail(state, payload) {
      state.email = payload.email;
    },
    setAddress(state, payload) {
      state.address = payload.address;
    },
    setZipCode(state, payload) {
      state.zip_code = payload.zip_code;
    },
    setIsSmoker(state, payload) {
      state.isSmoker = payload.isSmoker;
    },
    setHasPathologies(state, payload) {
      state.hasPathologies = payload.hasPathologies;
    },
    setKneeProblem(state, payload) {
      state.kneeProblem = payload.kneeProblem;
    },
    setShoulderProblem(state, payload) {
      state.shoulderProblem = payload.shoulderProblem;
    },
    setBackProblem(state, payload) {
      state.backProblem = payload.backProblem;
    },
    setSkeletalProblem(state, payload) {
      state.skeletalProblem = payload.skeletalProblem;
    },
    setHealthInfo(state, payload) {
      state.healthInfo = payload.healthInfo;
    },
    setWantsPersonalTrainer(state, payload) {
      state.wantsPersonalTrainer = payload.wantsPersonalTrainer;
    },
    setDoesWorkout(state, payload) {
      state.doesWorkout = payload.doesWorkout;
    },
    setYearsOfWorkout(state, payload) {
      state.yearsOfWorkout = payload.yearsOfWorkout;
    },
    setExerciseType(state, payload) {
      if (payload.exerciseType) {
        if (isNaN(payload.exerciseType)) {
          state.exerciseType = "4";
          state.exerciseTypeOther = payload.exerciseType;
        } else {
          state.exerciseType = payload.exerciseType;
        }
      }
    },
    setExerciseTypeOther(state, payload) {
      state.exerciseTypeOther = payload.exerciseTypeOther;
    },
    setExerciseDays(state, payload) {
      state.exerciseDays = payload.exerciseDays;
    },
    setSelectedFoods(state, payload) {
      state.selectedFoods = payload.selectedFoods;
    },
    setSelectedFoodsCon(state, val) {
      state.selectedFoodsCon = val;
    },
    setNonClinicalSelections(state, val) {
      state.nonClinicalSelections = val.nonClinicalSelections;
    },
    setSelectedNonClinical(state, val) {
      state.selectedNonClinical = val.selectedNonClinical;
    },
    setSelectedMedicalConditions(state, payload) {
      state.selectedMedicalConditions = payload.selectedMedicalConditions;
    },
    setSearchResults(state, results) {
      state.searchResults = results;
    },
    resetSearchResults(state) {
      state.searchResults = [];
    },
    setSearchRecommenderResults(state, results) {
      state.searchRecommenderResults = results;
    },
    resetSearchRecommenderResults(state) {
      state.searchRecommenderResults = [];
    },
    setQuestionnaireOptions(state, options) {
      for (var x in options) {
        state.questionnaireOptions.push({
          foodCategoryName: x,
          foodOptions: options[x],
        });
      }
    },
    setNonClinicalConditionsOptions(state, options) {
      state.nonClinicalOptions = options;
    },
    setMedicalConditionsOptions(state, options) {
      state.medicalConditionsOptions = options;
    },
    resetQuestionnaireOptions(state) {
      state.questionnaireOptions = [];
    },
    resetNonClinicalOptions(state) {
      state.nonClinicalOptions = [];
    },
    resetMedicalConditionsOptions(state) {
      state.medicalConditionsOptions = [];
    },
    resetAll(state) {
      state.userId = null;
      state.recommenderId = null;
      state.lastName = "";
      state.firstName = "";
      state.birthday = "";
      state.gender = null;
      state.cell = "";
      state.email = "";
      state.address = "";
      state.zip_code = "";
      state.isSmoker = null;
      state.hasPathologies = null;
      state.kneeProblem = null;
      state.shoulderProblem = null;
      state.backProblem = null;
      state.skeletalProblem = null;
      state.healthInfo = "";
      state.doesWorkout = null;
      state.yearsOfWorkout = null;
      state.wantsPersonalTrainer = null;
      state.exerciseType = null;
      state.exerciseDays = "";
      state.selectedFoods = [];
      state.nonClinicalOptions = [];
      state.selectedMedicalConditions = [];
      state.selectedNonClinical = [];
      state.nonClinicalSelections = [];
      state.selectedFoodsCon = [];
      state.searchResults = [];
      state.searchRecommenderResults = [];
      state.questionnaireOptions = [];
      state.medicalConditionsOptions = [];
    },
  },
  actions: {
    getQuestionnaire({ commit, rootState }, lang) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${process.env.VUE_APP_BASE_URL}/api/user/questionare?lang=${lang}&token=${rootState.auth.token}`,
            {}
          )
          .then((res) => {
            commit("setQuestionnaireOptions", res.data);
            resolve();
          })
          .catch((er) => {
            console.log(er);
            reject();
          });
      });
    },

    getMedicalConditions({ commit, rootState }, lang) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${process.env.VUE_APP_BASE_URL}/api/user/medical_conditions?lang=${lang}&token=${rootState.auth.token}`,
            {}
          )
          .then((res) => {
            commit("setMedicalConditionsOptions", res.data);
            resolve();
          })
          .catch((er) => {
            console.log(er);
            reject();
          });
      });
    },
    getNonClinicalConditions({ commit, rootState }, lang) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${process.env.VUE_APP_BASE_URL}/api/user/medical_conditions?lang=${lang}&type=non_clinical&token=${rootState.auth.token}`,
            {}
          )
          .then((res) => {
            commit("setNonClinicalConditionsOptions", res.data);
            resolve();
          })
          .catch((er) => {
            console.log(er);
            reject();
          });
      });
    },
    updateUserDetails({ state, rootState }) {
      let data = {
        token: rootState.auth.token,
        first_name: state.firstName,
        last_name: state.lastName,
        email: state.email,
        gender: state.gender,
        cell: state.cell.replace(/\s/g, ""),
        dob: state.birthday,
        address: state.address,
        zip_code: state.zip_code,
        is_smoking: state.isSmoker,
        has_pathology: state.hasPathologies,
        has_skeletic: state.skeletalProblem,
        has_knees: state.kneeProblem,
        has_shoulders: state.shoulderProblem,
        has_backpain: state.backProblem,
        health_comment: state.healthInfo,
        gym: state.doesWorkout,
        gym_years: state.yearsOfWorkout,
        gym_personal: state.wantsPersonalTrainer,
        gym_type: state.exerciseType,
        gym_type_other: state.exerciseTypeOther,
        gym_available: state.exerciseDays,
        foods: state.selectedFoodsCon,
        medical_conditions: state.selectedMedicalConditions,
        non_clinical_conditions: state.nonClinicalSelections,
        recommender_id: state.recommenderId,
      };
      if (state.newUser) {
        data.cell = state.cell.replace(/\s/g, "");
      } else {
        data.user_id = state.userId;
      }

      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BASE_URL}/api/user/form/edit`, data)
          .then((res) => {
            console.log(res.data);
            resolve();
          })
          .catch((er) => {
            console.log(er);
            reject();
          });
      });
    },
    recommenderSearch({ commit, rootState }, search) {
      if (search.length >= 9) {
        return new Promise((resolve, reject) => {
          axios
            .get(
              `${process.env.VUE_APP_BASE_URL}/api/user/recommender/search?search=${search}&token=${rootState.auth.token}`,
              {}
            )
            .then((res) => {
              commit("setSearchRecommenderResults", res.data.users);
              resolve();
            })
            .catch((er) => {
              console.log(er);
              reject();
            });
        });
      }
    },
    phoneSearch({ commit, rootState }, search) {
      if (search.length >= 6) {
        return new Promise((resolve, reject) => {
          axios
            .get(
              `${process.env.VUE_APP_BASE_URL}/api/user/cell/search?search=${search}&token=${rootState.auth.token}&version=3`,
              {}
            )
            .then((res) => {
              commit("setSearchResults", res.data.users);
              resolve();
            })
            .catch((er) => {
              console.log(er);
              reject();
            });
        });
      }
    },
    phoneCheck({ commit, rootState, state }, phoneObj) {
      let phone = phoneObj.number;
      let countryCode = phoneObj.country_code;
      return new Promise((resolve, reject) => {
        let endpoint = `${process.env.VUE_APP_BASE_URL}/api/user/cell/exists?cell=${phone}&token=${rootState.auth.token}&country_code=${countryCode}`;

        if (state.userId) {
          endpoint += `&user=${state.userId}`;
        }

        axios
          .get(endpoint, {})
          .then((res) => {
            commit("setPhoneExists", res.data.data.exists);
            commit("setPhoneRequestExists", res.data.data.request_exists);
            resolve();
          })
          .catch((er) => {
            console.log(er);
            reject();
          });
      });
    },
  },
};
