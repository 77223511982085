<template>
  <div class="login-div align-center  pa-5" id="logIn">
    <img src="@/assets/img/myFlex_white.png" class="logo mb-6" />
    <v-form
      @submit.prevent="submit"
      class="login-form d-flex flex-column"
      v-model="valid"
      ref="form"
    >
      <v-text-field
        class="mb-2"
        placeholder="email"
        type="email"
        outlined
        prepend-inner-icon="mdi-account"
        v-model="email"
        validate-on-blur
        :rules="emailErrors"
      ></v-text-field>

      <v-text-field
        class="mb-2"
        type="password"
        placeholder="password"
        outlined
        prepend-inner-icon="mdi-lock"
        v-model="password"
        :rules="passwordErrors"
      ></v-text-field>

      <v-btn color="primary" type="submit" class="mb-6" depressed large>{{
        $t("login")
      }}</v-btn>
    </v-form>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      email: "",
      password: "",
      valid: false
    };
  },

  computed: {
    emailErrors() {
      return [
        value => !!value || this.$t("error1"),
        value =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            value
          ) || this.$t("error5")
      ];
    },
    passwordErrors() {
      return [value => !!value || this.$t("error1")];
    }
  },
  methods: {
    ...mapActions(["login"]),
    submit() {
      if (this.$refs.form.validate()) {
        this.login({
          email: this.email,
          password: this.password
        })
          .then(() => {
            this.$router.push("/step1");
          })
          .catch(() => {});
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
