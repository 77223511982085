import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./assets/sass/variables.scss";
import i18n from "./i18n";
import axios from "axios";
import VueTelInputVuetify from "vue-tel-input-vuetify/lib";

Vue.config.productionTip = false;

Vue.use(VueTelInputVuetify, {
  vuetify
});

const token = localStorage.getItem("token");
if (token) {
  axios.defaults.headers.common["Authorization"] = "JWT " + token;
}

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status === 401) {
      store.dispatch("logout");
    }
    throw error;
  }
);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app");
